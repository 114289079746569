<script>
import { usePageTabs } from '@/use/usePageTabs'
import { useRouter } from 'vue-router/composables'
import { computed, defineComponent } from 'vue'
import { filter, includes } from 'lodash'

// interface TabItemContextMenuProps {
//   target: TabItem | null
// }

export default defineComponent({
  name: 'TabItemContextMenu',
  props: {
    target: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { refreshTab, closeTab, closeLeftTabs, closeOtherTabs, closeRightTabs, pinTab, isPinTab, unpinTab } = usePageTabs()
    const router = useRouter()

    const onReload = () => {
      if (props.target.name) refreshTab(props.target)
      emit('close')
    }

    const onCloseTab = () => {
      if (props.target.name) closeTab(props.target, router)
      emit('close')
    }

    const onCloseLeftTab = () => {
      if (props.target.name) closeLeftTabs(props.target, router)
      emit('close')
    }

    const onCloseRightTab = () => {
      if (props.target.name) closeRightTabs(props.target, router)
      emit('close')
    }

    const onCloseOtherTab = () => {
      if (props.target.name) closeOtherTabs(props.target, router)
      emit('close')
    }

    const options = computed(() => {
      const fields = [
        {
          label: '關閉分頁',
          icon: 'close',
          key: 'close',
          handler: onCloseTab,
        },
        {
          label: '關閉左側分頁',
          icon: 'chevron_left',
          key: 'close-left',
          handler: onCloseLeftTab,
        },
        {
          label: '關閉右側分頁',
          icon: 'chevron_right',
          key: 'close-right',
          handler: onCloseRightTab,
        },
        {
          label: '關閉其他分頁',
          icon: 'code',
          key: 'close-other',
          handler: onCloseOtherTab,
        },
        {
          label: '固定分頁',
          icon: '📌',
          key: 'pin',
          handler: () => {
            pinTab(props.target)
            emit('close')
          },
        },
      ]

      if (isPinTab.value(props.target)) {
        const computedFields = filter(fields, (field) => !includes([
          'close',
          'close-left',
          'close-right',
          'close-other',
          'pin',
        ], field.key))

        computedFields.push({
          label: '取消固定分頁',
          icon: 'cancel',
          key: 'unpin',
          handler: () => {
            unpinTab(props.target)
            emit('close')
          },
        })

        return computedFields
      }

      return fields
    })

    return {
      options,
      onReload,
      onCloseTab,
      onCloseLeftTab,
      onCloseRightTab,
      onCloseOtherTab,
      isPinTab,
    }
  },
})

</script>

<template>
  <div class="page-tab-item-context-menu">
    <div class="context-item-wrapper">
      <div
        v-for="option in options"
        :key="option.key"
        class="context-item"
        @click="option.handler"
      >
        <MaterialIcon size="18" class="relative" style="top: 1px">{{ option.icon }}</MaterialIcon>
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
   .page-tab-item-context-menu{
        @apply bg-white shadow-xl fixed;
        @apply p-[10px] rounded-[5px];
        z-index: 10000;
        transform: scale(0);
        transform-origin: top left;
        transition: .1s ease-in-out;
   }

    .context-item-wrapper {
        width: 100%;
        height: 100%;
    }

    .context-item {
      @apply flex items-center gap-[8px] cursor-pointer rounded-[10px] px-[10px] py-[3px];
    }

    .context-item:hover {
      @apply bg-primary-30;
    }
</style>
