<template>
  <div class="tabbar">
    <!-- <el-scrollbar x-scrollable always class="flex-1"> -->
    <!-- <Sortable :list="tabs" item-key="name" class="flex gap-10px" :options="{ animation: 150 }"> -->
    <!-- <template #item="{ element }"> -->
    <!-- <TabItemContextMenu :target="selectedItem"> -->
    <!-- <TabItemCmp :data="element" @contextmenu="onContextMenu($event, element)" /> -->
    <!-- </TabItemContextMenu> -->
    <!-- </template> -->
    <!-- </Sortable> -->
    <!-- </el-scrollbar> -->

    <div class="flex justify-between w-full items-center" style="gap: 20px">
      <draggable
        v-model="state.tabs"
        ghost-class="ghost"
        :animation="200"
        group="description"
        @change="$emit('change', { type: 'activity', data: state.tabs })"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="w-full flex gap-[10px] overflow-x-auto">
          <PageTabItem
            v-for="tab in state.tabs"
            :key="tab.name"
            class="flex-shrink-0"
            :data="tab"
            @contextmenu.native="onContextMenu($event, tab)"
          />
        </transition-group>
      </draggable>

      <div class="cursor-pointer" @click="modal.setting = true">
        <MaterialIcon color="var(--gray-80)">settings</MaterialIcon>
      </div>
    </div>

    <!-- <div class="flex-shrink-0 pb-20px"> -->
    <!-- <el-divider direction="vertical" /> -->

    <!-- <BaseElButton name="reload-page" type="info" @click="reloadPage">
        <div class="flex items-center gap-sm">
          <p>Reload</p>
        </div>
      </BaseElButton> -->
    <!-- </div> -->

    <TabItemContextMenu
      ref="contextMenuRef"
      :target="selectedItem"
      @close="closeTabItemContextMenu"
    />
    <PageTabSettingModal
      v-if="modal.setting"
      @close="modal.setting = false"
    />
  </div>
</template>

<script>
import { usePageTabs } from '@/use/usePageTabs'
import PageTabItem from './components/PageTabItem.vue'
import TabItemContextMenu from './components/TabItemContextMenu.vue'
// import { IconReload, IconMenu2 } from '@tabler/icons-vue'
import { defineComponent, reactive, ref } from 'vue'
import PageTabSettingModal from './components/PageTabSettingModal.vue'
import { onClickOutside } from '@vueuse/core'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'PageTabs',
  components: {
    draggable,
    PageTabItem,
    TabItemContextMenu,
    PageTabSettingModal,
  },
  setup (props, { emit }) {
    const { state, refreshTab, isPinTab } = usePageTabs()
    const drag = ref(false)
    const contextMenuRef = ref(null)
    onClickOutside(contextMenuRef, (event) => {
      closeTabItemContextMenu()
    })
    const selectedItem = ref(null)
    const modal = reactive({
      setting: false,
    })

    const reloadPage = () => {
      if (state.activeTab) refreshTab(state.activeTab)
    }

    const onContextMenu = (e, item) => {
      e.preventDefault()
      selectedItem.value = item
      contextMenuRef.value.$el.classList.remove('context-active')
      contextMenuRef.value.$el.style.top = e.clientY + 'px'
      contextMenuRef.value.$el.style.left = e.clientX + 'px'

      setTimeout(() => {
        contextMenuRef.value.$el.classList.add('context-active')
      }, 150)
    }

    const closeTabItemContextMenu = () => {
      selectedItem.value = null
      contextMenuRef.value.$el.classList.remove('context-active')
    }

    return {
      drag,
      modal,
      state,
      reloadPage,
      onContextMenu,
      selectedItem,
      contextMenuRef,
      closeTabItemContextMenu,
    }
  },
})

</script>

<style lang="postcss" scoped>
.tabbar {
  @apply flex items-center gap-[10px] flex-nowrap pb-[10px] mb-[15px];
  @apply border-b-[1px] border-solid border-gray-30;
}

.context-active {
    transform: scale(1) !important;
}

.ghost {
  background-color: rgba(150, 150, 150, 0.2) !important;
}

.flip-list-move {
  transition: transform 0.5s;
}

</style>
